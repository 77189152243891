<template>
  <div class="merchant-add">
    <GlobalChunk icon="add" title="添加商户" :padding="[32, 0, 95, 42]">
      <!-- 编辑 -->
      <span v-if="this.$route.query.id" class="title_c" slot="tip"
        >已开通线上小程序或H5商城，其客户可在线测评旧机并引流到店，同时可售卖门店靓机业务</span
      >
      <!-- 添加 -->
      <span v-else class="title_c" slot="tip"
        >此类商户添加时需配置小程序或公众号参数、短信参数（创蓝253平台）</span
      >
      <el-row>
        <el-col :span="12">
          <!-- :model="formData" -->
          <!-- :rules="formRules" -->
          <el-form
            :model="formData"
            ref="ruleForm"
            label-width="140px"
            class="demo-ruleForm"
            :rules="formRules"
          >
            <el-form-item
              v-if="this.$route.query.id"
              class="el_from"
              :label="this.$route.query.id ? '门店商户：' : '选择门店商户：'"
              prop="companyId"
            >
              <span>{{ formData.companyName }}</span>
            </el-form-item>
            <el-form-item
              v-else
              class="el_from"
              label="选择门店商户："
              prop="companyId"
            >
              <el-select
                filterable=""
                v-model="formData.companyId"
                style="width: 100%"
                clearable
                placeholder="请选择已有门店商户"
              >
                <el-option
                  v-for="item in option"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="门店推荐方式：">
              <el-radio-group v-model="formData.storeRecommendWay">
                <el-radio label="01">优先按距离位置最近</el-radio>
                <el-radio label="02">优先按分享绑定关系</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="el_from" label="回收方式配置：">
              <el-radio-group v-model="formData.configWay">
                <el-radio label="01">仅到店回收</el-radio>
                <el-radio label="02">到店及上门回收</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="el_from" label="C端展示方式：">
              <el-radio-group
                @change="resetForm('ruleForm')"
                v-model="formData.mallShowVos.showType"
                :disabled="this.$route.query.id ? true : false"
              >
                <el-radio label="01">小程序</el-radio>
                <!-- <el-radio label="02" disabled>H5</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <!-- 微信   公众号 start-->
            <el-form-item
              class="el_from"
              :label="
                formData.mallShowVos.showType == '01'
                  ? '小程序商户名称：'
                  : '公众号商家名称：'
              "
              :rules="{
                required: true,
                message:
                  formData.mallShowVos.showType == '01'
                    ? '请输入微信小程序名称'
                    : '请输入微信公众号名称',
                trigger: 'blur',
              }"
            >
              <el-input
                :disabled="this.$route.query.id ? true : false"
                v-model="formData.mallShowVos.name"
                :placeholder="
                  formData.mallShowVos.showType == '01'
                    ? '请输入微信小程序名称'
                    : '请输入微信公众号名称'
                "
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="formData.mallShowVos.showType == '01'"
              class="el_from"
              label="小程序商户简称："
              :rules="{
                required: true,
                message: '请输入微信小程序商户简称',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="formData.mallShowVos.abbreviation"
                placeholder="请输入微信小程序商户简称"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="el_from"
              :label="
                formData.mallShowVos.showType == '01'
                  ? '小程序APP-ID：'
                  : '公众号APP-ID：'
              "
              :rules="{
                required: true,
                message:
                  formData.mallShowVos.showType == '01'
                    ? '请输入微信小程序APP-ID'
                    : '请输入微信公众号APP-ID',
                trigger: 'blur',
              }"
            >
              <el-input
                :disabled="this.$route.query.id ? true : false"
                v-model="formData.mallShowVos.appId"
                :placeholder="
                  formData.mallShowVos.showType == '01'
                    ? '请输入微信小程序APP-ID'
                    : '请输入微信公众号APP-ID'
                "
              ></el-input>
            </el-form-item>
            <el-form-item
              class="el_from"
              :label="
                formData.mallShowVos.showType == '01'
                  ? '小程序秘钥：'
                  : '公众号秘钥：'
              "
              :rules="{
                required: true,
                message:
                  formData.mallShowVos.showType == '01'
                    ? '请输入微信小程序秘钥'
                    : '请输入微信公众号秘钥',
                trigger: 'blur',
              }"
            >
              <el-input
                :disabled="this.$route.query.id ? true : false"
                v-model="formData.mallShowVos.secretKey"
                :placeholder="
                  formData.mallShowVos.showType == '01'
                    ? '请输入微信小程序秘钥'
                    : '请输入微信公众号秘钥'
                "
              ></el-input>
            </el-form-item>

            <el-form-item
              class="el_from"
              :label="
                formData.mallShowVos.showType == '01'
                  ? '小程序商户logo：'
                  : '公众号商户logo：'
              "
              prop="mallShowVos.secretKey"
            >
              <ElImgUpload
                :exist-image="formData.mallShowVos.logo"
                place-txt="只能上传一张图片，文件格式为jpg/png，文件大小不的超过500KB，建议尺寸:80*80px"
                @handleDelete="handleUploadDelete(formData.mallShowVos.logo)"
                @handleChange="handleUploadChange($event)"
              />
            </el-form-item>
            <!-- 微信   公众号 end-->
            <div class="flex" style="margin-bottom: 20px">
              <img
                style="width: 20px; height: 20px; margin-right: 6px"
                src="@/assets/images/iocn_ddlb@2x.png"
                alt
              />
              <span>短信配置</span>
              <span style="font-size: 12px; color: #ff687b; margin-left: 20px"
                >配置短信平台接口账号及秘钥
                (提前联系开发人员配置，可上线前配置，配置后不可修改)</span
              >
            </div>
            <el-form-item class="el_from" label="短信账号：" prop="smsAccount">
              <el-input
                :disabled="smsAccountDisabled"
                v-model="formData.smsAccount"
                placeholder="请输入短信账号（限20字内）"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="el_from"
              label="短信密钥："
              prop="smsSecretKey"
            >
              <el-input
                :disabled="smsSecretKeyDisabled"
                v-model="formData.smsSecretKey"
                placeholder=" 请输入短信密钥（限25字内）"
                maxlength="25"
              ></el-input>
            </el-form-item>
            <el-form-item class="el_from" label="短信签名：" prop="smsSign">
              <el-input
                :disabled="smsSignDisabled"
                v-model="formData.smsSign"
                placeholder="请输入短信签名（限6字内）"
                maxlength="6"
              ></el-input>
            </el-form-item>

            <div class="flex" style="margin-bottom: 20px">
              <img
                style="width: 20px; height: 20px; margin-right: 6px"
                src="@/assets/images/iocn_ddlb@2x.png"
                alt
              />
              <span>时间设置</span>
            </div>
            <el-form-item class="el_from" label="到期时间：" prop="expireTime">
              <el-date-picker
                v-model="formData.expireTime"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item class="el_from" label="备注：" prop="remark">
              <el-input
                type="textarea"
                v-model="formData.remark"
                placeholder="请输入备注（限100字内）"
                maxlength="100"
                :autosize="{ minRows: 4, maxRows: 4 }"
                resize="none"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item class="el_from">
              <el-button size="small" @click="toBack">返回</el-button>
              <el-button
                size="small"
                @click="FormDataCommit('ruleForm')"
                type="primary"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </GlobalChunk>
    <el-dialog
      title="授权验证"
      :visible.sync="DialogVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="DialogVisible = false"
    >
      <div class="mb-20">动态验证码</div>
      <el-input
        v-model="formData.command"
        placeholder="请输入谷歌动态验证码"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="DialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="FormConfirmDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import _api from "@/utils/request";
export default {
  name: "merchant-add",
  components: {
    ElImgUpload,
  },
  data() {
    return {
      option: [],
      DialogVisible: false,
      smsSignDisabled: false,
      smsAccountDisabled: false,
      smsSecretKeyDisabled: false,
      formData: {
        command: "",
        companyId: "", //选择门店商户
        configWay: "01", //回收方式配置
        expireTime: "",
        id: "",
        mallShowVos: {
          abbreviation: "",
          appId: "",
          logo: "",
          name: "",
          secretKey: "",
          showType: "01",
        },
        remark: "",
        smsAccount: "",
        smsSecretKey: "",
        smsSign: "",
        storeRecommendWay: "01", //门店推荐方式
      },
      formRules: {
        companyId: [
          {
            required: true,
            trigger: "blur",
            message: "请输入商户名称",
          },
        ],
        expireTime: [
          {
            required: true,
            trigger: "blur",
            message: "请选择到期时间",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.$route.query.id) {
      // 编辑
      this.mallInfo();
    } else {
      // 添加
      this.SelectList();
    }
  },
  methods: {
    // 详情
    mallInfo() {
      _api.mallInfo({ id: this.$route.query.id }).then((res) => {
        console.log(res);
        this.formData.mallShowVos = res.data.mallShowViews[0];
        this.formData.command = "";
        this.formData.companyId = res.data.companyId;
        this.formData.configWay = res.data.configWay;
        this.formData.expireTime = res.data.expireTime;
        this.formData.id = res.data.id;
        this.formData.remark = res.data.remark;
        this.formData.smsAccount = res.data.smsAccount;
        this.formData.smsSecretKey = res.data.smsSecretKey;
        this.formData.smsSign = res.data.smsSign;
        this.formData.storeRecommendWay = res.data.storeRecommendWay;
        this.formData.companyName = res.data.companyName;
        this.smsSecretKeyDisabled = this.formData.smsSecretKey ? true : false;
        this.smsAccountDisabled = this.formData.smsAccount ? true : false;
        this.smsSignDisabled = this.formData.smsSign ? true : false;
      });
    },
    // 下拉
    SelectList() {
      _api.getSelectList().then((res) => {
        console.log(res);
        this.option = res.data;
      });
    },
    // 切换重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.formData.mallShowVos.abbreviation = "";
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url, key) {
      this.formData.mallShowVos.logo = url;
    },
    //删除图片
    handleUploadDelete(key) {
      this.formData.mallShowVos.logo = "";
    },
    // 返回上一页
    toBack() {
      this.$router.go(-1);
    },
    // 提交表单打开验证
    FormDataCommit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.command = "";
          this.DialogVisible = true;
        } else {
          return false;
        }
      });
    },
    // 提交表单
    FormConfirmDialog() {
      if (!this.formData.command) {
        this.$message.error("请输入谷歌动态口令");
        return;
      }
      this.formData.expireTime = new Date(this.formData.expireTime).getTime();
      console.log(this.formData.expireTime);
      let data = JSON.parse(JSON.stringify(this.formData));
      data.mallShowVos = [this.formData.mallShowVos];
      _api
        .mallAddOrUpdate(data)
        .then((res) => {
          if (res.code === 1) {
            this.DialogVisible = false;
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
            this.DialogVisible = false;
          }
        })
        .catch((err) => {
          // this.$message.error(err.msg);
          this.DialogVisible = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
/deep/ .el-select {
  width: 100%;
}
.title_c {
  font-size: 14px !important;
  color: #989898 !important;
  font-weight: 500 !important;
  margin-left: 20px !important;
}
/deep/.global-chunk_header_filter {
  padding: 0;
}
.flex {
  display: flex;
  align-items: center;
}
.mb-20 {
  margin-bottom: 20px;
}
</style>
